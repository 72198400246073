// import React, { useState } from 'react';
// import axios from 'axios';

// const FileConverter = () => {
//   const [file, setFile] = useState(null);
//   const [convertedFiles, setConvertedFiles] = useState([]);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!file) {
//       alert('Please select a file to convert.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('files', file);

//     try {
//       const response = await axios.post('http://localhost:5001/convert', formData, {
//         responseType: 'blob',
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       const convertedFile = new File([response.data], `${file.name.split('.')[0]}_converted.${file.name.endsWith('.doc') ? 'docx' : 'doc'}`, {
//         type: 'application/msword',
//       });

//       setConvertedFiles([...convertedFiles, convertedFile]);
//     } catch (err) {
//       console.error('Error converting file:', err);
//       alert('An error occurred while converting the file.');
//     }
//   };

//   const handleDownload = (file) => {
//     const url = window.URL.createObjectURL(file);
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', file.name);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div>
//       <h1>File Converter</h1>
//       <form onSubmit={handleSubmit}>
//         <input type="file" onChange={handleFileChange} accept=".doc,.docx" multiple />
//         <button type="submit">Convert</button>
//       </form>
//       <h2>Converted Files</h2>
//       {convertedFiles.length > 0 ? (
//         <ul>
//           {convertedFiles.map((file, index) => (
//             <li key={index}>
//               <span>{file.name}</span>
//               <button onClick={() => handleDownload(file)}>Download</button>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No converted files yet.</p>
//       )}
//     </div>
//   );
// };

// export default FileConverter;


// import React, { useState } from 'react';
// import axios from 'axios';

// function App() {
//     const [file, setFile] = useState(null);

//     const onFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const onFileUpload = () => {
//         const formData = new FormData();
//         formData.append('file', file);

//         axios.post('http://localhost:6789/convert', formData, {
//             responseType: 'blob',
//         })
//         .then((res) => {
//             const url = window.URL.createObjectURL(new Blob([res.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', file.name);
//             document.body.appendChild(link);
//             link.click();
//         })
//         .catch((err) => {
//             console.log(err);
//         });
//     };

//     return (
//         <div>
//             <input type="file" onChange={onFileChange} />
//             <button onClick={onFileUpload}>Upload and Convert</button>
//         </div>
//     );
// }

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';

// function App() {
//     const [file, setFile] = useState(null);

//     const onFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const onFileUpload = () => {
//         const formData = new FormData();
//         formData.append('file', file);

//         axios.post('http://localhost:6789/convert', formData, {
//             responseType: 'blob',
//             headers: {
//                         'Content-Type': 'multipart/form-data',
//                       },
//         })
//         .then((res) => {
//             const url = window.URL.createObjectURL(new Blob([res.data]));
//             const link = document.createElement('a');
//             const convertedExtension = file.name.endsWith('.docx') ? 'doc' : 'docx';
//             const convertedFilename = file.name.replace(/\.[^.]+$/, `.${convertedExtension}`);
//             link.href = url;
//             link.setAttribute('download', convertedFilename);
//             document.body.appendChild(link);
//             link.click();
//         })
//         .catch((err) => {
//             console.log(err);
//         });
//     };

//     return (
//         <div>
//             <input type="file" onChange={onFileChange} multiple />
//             <button onClick={onFileUpload}>Upload and Convert</button>
//         </div>
//     );
// }

// export default App;


// // src/App.js
// import React, { useState } from 'react';
// import axios from 'axios';

// function App() {
//     const [downloadLink, setDownloadLink] = useState('');

//     const handleFileUpload = async (e) => {
//         e.preventDefault();
//         const formData = new FormData();

//         for (const file of e.target.files) {
//             formData.append('docxFiles', file);
//         }

//         try {
//             const response = await axios.post('http://localhost:4000/convert', formData);
//             const zipPath = response.data.pdfPaths[0]; 

//             // Create a temporary download link
//             const url = window.URL.createObjectURL(new Blob([zipPath]));
//             setDownloadLink(url);
//         } catch (error) {
//             console.error('Error converting DOCX to PDF:', error);
//         }
//     };

//     return (
//         <div>
//             <input type="file" accept=".docx" multiple onChange={handleFileUpload} />
//             {downloadLink && (
//                 <a href={downloadLink} download="converted_files.zip">
//                     Download Converted Files (ZIP)
//                 </a>
//             )}
//         </div>
//     );
// }

// export default App;


// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;
  
//     setIsLoading(true);
  
//     try {
//       const formData = new FormData();
//       formData.append('file', file);
  
//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
  
//       const { success, outputFilePath, error } = response.data;
//       if (success) {

//         window.open(`http://localhost:4001/${outputFilePath}`, '_blank');
//       } else {
//         setError(error || 'An error occurred while converting the file');
//       }
//     } catch (err) {
//       if (err.response && err.response.data) {
//         setError(err.response.data.error || 'An error occurred while converting the file');
//       } else {
//         setError('An error occurred while communicating with the server');
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };
  

//   return (
//     <div>
//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       <input type="file" onChange={handleFileChange} />
//     </div>
//   );
// };

// export default App;


// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [outputFilePath, setOutputFilePath] = useState(null);

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       formData.append('file', file);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const { success, outputFilePath: path, error } = response.data;
//       if (success) {
//         setOutputFilePath(path); // Set outputFilePath received from server
//       } else {
//         setError(error || 'An error occurred while converting the file');
//       }
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDownload = async () => {
//     if (outputFilePath) {
//       try {
//         const downloadUrl = `http://localhost:4001/${outputFilePath}`;
//         const response = await axios.get(downloadUrl, { responseType: 'blob' });

//         const blob = new Blob([response.data], { type: 'application/pdf' });
//         const url = window.URL.createObjectURL(blob);

//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', 'converted.pdf');
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } catch (err) {
//         console.error('Error downloading file:', err);
//       }
//     }
//   };

//   return (
//     <div>
//       {error && <p>Error: {error}</p>}
//       {outputFilePath && (
//           <div>
//           <button onClick={handleDownload}>Download Converted File</button>
//           <br />
//           <br />
//         </div>
//       )}
//       <input type="file" onChange={handleFileChange} />
//       {isLoading && <p>Loading...</p>}
//     </div>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [outputFilePath, setOutputFilePath] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;
  
//     setIsLoading(true);
  
//     try {
//       const formData = new FormData();
//       formData.append('file', file);
//       formData.append('format', conversionFormat); 
  
//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
  
//       const { success, outputFilePath: path, error } = response.data;
//       if (success) {
//         setOutputFilePath(path);
//       } else {
//         setError(error || 'An error occurred while converting the file');
//       }
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };
  

//   const handleDownload = async () => {
//     if (outputFilePath) {
//       try {
//         const url = `http://localhost:4001/${outputFilePath}`;

//         const response = await axios.get(url, {
//           responseType: 'blob',
//         });

//         const blob = new Blob([response.data], { type: 'application/pdf' });
//         const downloadUrl = window.URL.createObjectURL(blob);

//         const link = document.createElement('a');
//         link.href = downloadUrl;
//         link.download = 'converted_file.doc'; 
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } catch (err) {
//         console.error('Error downloading file:', err);
//       }
//     }
//   };

//   return (
//     <div>
//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {outputFilePath && (
//         <div>
//           <button onClick={handleDownload}>Download Converted File</button>
//           <br />
//           <br />
//         </div>
//       )}
//       <label>
//         Select Conversion Format:
//         <select value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//         </select>
//       </label>
//       <br />
//       <br />
//       <input type="file" onChange={handleFileChange} />
//     </div>
//   );
// };

// export default App;


// import React, { useState } from 'react';
// import axios from 'axios';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [outputFilePaths, setOutputFilePaths] = useState([]);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const { success, outputFiles, error } = response.data;
//       if (success) {
//         setOutputFilePaths(outputFiles);
//       } else {
//         setError(error || 'An error occurred while converting the files');
//       }
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDownload = async (outputFilePath) => {
//     try {
//       const url = `http://localhost:4001/${outputFilePath}`;

//       const response = await axios.get(url, {
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data], { type: 'application/octet-stream' });
//       const downloadUrl = window.URL.createObjectURL(blob);

//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = `converted_file.${conversionFormat === 'docx' ? 'doc' : 'docx'}`;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (err) {
//       console.error('Error downloading file:', err);
//     }
//   };

//   return (
//     <div>
//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {outputFilePaths.length > 0 && (
//         <div>
//           <p>Converted files:</p>
//           <ul>
//             {outputFilePaths.map((filePath, index) => (
//               <li key={index}>
//                 <button onClick={() => handleDownload(filePath)}>Download File {index + 1}</button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       <label>
//         Select Conversion Format:
//         <select value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//         </select>
//       </label>
//       <br />
//       <br />
//       <input type="file" onChange={handleFileChange} multiple />
//     </div>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [outputFilePaths, setOutputFilePaths] = useState([]);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const { success, outputFiles, error } = response.data;
//       if (success) {
//         setOutputFilePaths(outputFiles);
//       } else {
//         setError(error || 'An error occurred while converting the files');
//       }
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDownload = async (outputFilePath) => {
//     try {
//       const url = `http://localhost:4001/${outputFilePath}`;

//       const response = await axios.get(url, {
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data], { type: 'application/octet-stream' });
//       const downloadUrl = window.URL.createObjectURL(blob);

//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = `converted_file.${conversionFormat === 'docx' ? 'doc' : 'docx'}`;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (err) {
//       console.error('Error downloading file:', err);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className="mb-3">
//         <input className="form-control" type="file" onChange={handleFileChange} multiple />
//         <p>Drop one or more files here or</p>
       
       
//       </div>
      
//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {outputFilePaths.length > 0 && (
//         <div>
//           <p>Converted files:</p>
//           <ul>
//             {outputFilePaths.map((filePath, index) => (
//               <li key={index}>
//                 <button className="btn btn-success" onClick={() => handleDownload(filePath)}>Download File {index + 1}</button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [outputFilePaths, setOutputFilePaths] = useState([]);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       const { success, outputFiles, error } = response.data;
//       if (success) {
//         setOutputFilePaths(outputFiles);
//       } else {
//         setError(error || 'An error occurred while converting the files');
//       }
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDownload = async (outputFilePath) => {
//     try {
//       const url = `http://localhost:4001/${outputFilePath}`;

//       const response = await axios.get(url, {
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data], { type: 'application/octet-stream' });
//       const downloadUrl = window.URL.createObjectURL(blob);

//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = `converted_file.${conversionFormat === 'docx' ? 'doc' : 'docx'}`;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (err) {
//       console.error('Error downloading file:', err);
//     }
//   };

//   const handleDownloadZip = async () => {
//     try {
//       const response = await axios.get('http://localhost:4001/download-zip', {
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data], { type: 'application/zip' });
//       const downloadUrl = window.URL.createObjectURL(blob);

//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = 'converted_files.zip';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (err) {
//       console.error('Error downloading zip file:', err);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className="mb-3">
//         <input className="form-control" type="file" onChange={handleFileChange} multiple />
//         <p>Drop one or more files here or</p>
//       </div>
      
//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {outputFilePaths.length > 0 && (
//         <div>
//           <p>Converted files:</p>
//           <ul>
//             {outputFilePaths.map((filePath, index) => (
//               <li key={index}>
//                 <button className="btn btn-success" onClick={() => handleDownload(filePath)}>Download File {index + 1}</button>
//               </li>
//             ))}
//           </ul>
//           <button className="btn btn-primary" onClick={handleDownloadZip}>Download Zip</button>
//         </div>
//       )}
//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob' // Receive response as a blob
//       });

//       // Save the zip file to the user's device
//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className="mb-3">
//         <input className="form-control" type="file" onChange={handleFileChange} multiple />
//         <p>Drop one or more files here or</p>
//       </div>

//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}

//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default App;



// import React, { useState } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');

//   const handleFileChange = async (event) => {
//     const files = event.target.files;
//     if (!files || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob'
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className="mb-3">
//         <input className="form-control" type="file" onChange={handleFileChange} multiple />
//         <p>Drop one or more files here or</p>
//       </div>

//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}

//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//           <option value="pdf">PDF</option>
//           <option value="md">MD</option>
//           <option value="html">HTML</option>
//           <option value="txt">TXT</option>
//           <option value="dot">DOT</option>
//           <option value="docm">DOCM</option>
//           <option value="dotx">DOTX</option>
//           <option value="dotm">DOTM</option>
//           <option value="rtf">RTF</option>
//           <option value="epub">EPUB</option>
//           <option value="ps">PS</option>
//           <option value="pcl">PCL</option>
//           <option value="mhtml">MHTML</option>
//           <option value="odt">ODT</option>
//           <option value="ott">OTT</option>
//           <option value="xps">XPS</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default App;



// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');
//   const [files, setFiles] = useState([]);

//   const onDrop = useCallback(acceptedFiles => {
//     setFiles(acceptedFiles);
//   }, []);

//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   const handleStartConversion = async () => {
//     if (files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob'
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//       setFiles([]);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className="mb-3" {...getRootProps()}>
//         <input {...getInputProps()} />
//         <p>Drop one or more files here or</p>
//       </div>

//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}

//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//           <option value="pdf">PDF</option>
//           <option value="md">MD</option>
//           <option value="html">HTML</option>
//           <option value="txt">TXT</option>
//           <option value="dot">DOT</option>
//           <option value="docm">DOCM</option>
//           <option value="dotx">DOTX</option>
//           <option value="dotm">DOTM</option>
//           <option value="rtf">RTF</option>
//           <option value="epub">EPUB</option>
//           <option value="ps">PS</option>
//           <option value="pcl">PCL</option>
//           <option value="mhtml">MHTML</option>
//           <option value="odt">ODT</option>
//           <option value="ott">OTT</option>
//           <option value="xps">XPS</option>
//         </select>
//       </div>

//       <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0}>Start Conversion</button>
//     </div>
//   );
// };

// export default App;




// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');
//   const [files, setFiles] = useState([]);
//   const [progress, setProgress] = useState(0);

//   const onDrop = useCallback(acceptedFiles => {
//     setFiles(acceptedFiles);
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   const handleStartConversion = async () => {
//     if (files.length === 0) return;

//     setIsLoading(true);
//     setProgress(0);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob',
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//           setProgress(percentCompleted);
//         }
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//       setFiles([]);
//       setProgress(0);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1>Add Documents</h1>
//       <div className={`dropzone ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
//         <input {...getInputProps()} />
//         <p>{isDragActive ? 'Drop the files here' : 'Drop one or more files here'}</p>
//         {files.length > 0 && (
//           <div>
//             <h4>Selected files:</h4>
//             <ul>
//               {files.map(file => (
//                 <li key={file.name}>{file.name}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {progress > 0 && (
//           <div className="progress">
//             <div
//               className="progress-bar"
//               role="progressbar"
//               style={{ width: `${progress}%` }}
//               aria-valuenow={progress}
//               aria-valuemin="0"
//               aria-valuemax="100"
//             >
//               {progress}%
//             </div>
//           </div>
//         )}
//       </div>

//       {isLoading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}

//       <div className="mb-3">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOCX to DOC</option>
//           <option value="doc">DOC to DOCX</option>
//           <option value="pdf">PDF</option>
//           <option value="md">MD</option>
//           <option value="html">HTML</option>
//           <option value="txt">TXT</option>
//           <option value="dot">DOT</option>
//           <option value="docm">DOCM</option>
//           <option value="dotx">DOTX</option>
//           <option value="dotm">DOTM</option>
//           <option value="rtf">RTF</option>
//           <option value="epub">EPUB</option>
//           <option value="ps">PS</option>
//           <option value="pcl">PCL</option>
//           <option value="mhtml">MHTML</option>
//           <option value="odt">ODT</option>
//           <option value="ott">OTT</option>
//           <option value="xps">XPS</option>
//         </select>
//       </div>

//       <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0}>Start Conversion</button>
//     </div>
//   );
// };

// export default App;

// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');
//   const [files, setFiles] = useState([]);


//   const onDrop = useCallback(acceptedFiles => {
//     setFiles(acceptedFiles);
//     // Calculate the total size of all files
  
   
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   const handleStartConversion = async () => {
//     if (files.length === 0) return;

//     setIsLoading(true);
   

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob',
        
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//       setFiles([]);
      
//     }
//   };

//  ;

//   return (
//     <div className="container mt-5">
//         <div className='row'>
//             <p className='fw-bold text-center h5'>DOCX Converter Wizard</p>
//         <div className="col-lg-12 mt-3">
//   <div className={`dropzone ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
//     <input {...getInputProps()} accept='.doc .docx'/>
//     <div className="border border-primary rounded p-5 text-center">
//       <p className="mb-0">{isDragActive ? 'Drop the files here' : 'Drop one or more files here'}</p>
//       {/* {files.length > 0 && (
//         <div>
//           <h4 className="mt-3">Selected files:</h4>
//           <ul className="list-unstyled">
//             {files.map(file => (
//               <li key={file.name}>{file.name}</li>
//             ))}
//           </ul>
//         </div>
//       )} */}
//     </div>
//   </div>
// </div>

         
        
    
     

//          {isLoading && <p className='text-center mt-3'>Loading...</p>}
//       {error && <p>Error: {error}</p>}


//       <div className="col-lg-4 mb-3 mt-2 text-center">
//         <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//         <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOC</option>
//           <option value="doc">DOCX</option>
//           <option value="pdf">PDF</option>
//           <option value="md">MD</option>
//           <option value="html">HTML</option>
//           <option value="txt">TXT</option>
//           <option value="dot">DOT</option>
//           <option value="docm">DOCM</option>
//           <option value="dotx">DOTX</option>
//           <option value="dotm">DOTM</option>
//           <option value="rtf">RTF</option>
//           <option value="epub">EPUB</option>
//           <option value="ps">PS</option>
//           <option value="pcl">PCL</option>
//           <option value="mhtml">MHTML</option>
//           <option value="odt">ODT</option>
//           <option value="ott">OTT</option>
//           <option value="xps">XPS</option>
//         </select>
//       </div>
// <div className=''>

//       <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0}>Start Conversion</button>
// </div>
//       </div>
//     </div>
//   );
// };

// export default App;



// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');
//   const [files, setFiles] = useState([]);

//   const onDrop = useCallback(acceptedFiles => {
//     const filteredFiles = acceptedFiles.filter(file => {
//       const acceptedFormats = ['.doc', '.docx'];
//       const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
//       return acceptedFormats.includes(fileExtension.toLowerCase());
//     });
//     setFiles(filteredFiles);
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   const handleStartConversion = async () => {
//     if (files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//       setFiles([]);
//     }
//   };

//   const fileCounts = files.reduce((acc, file) => {
//     const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
//     acc[fileExtension] = (acc[fileExtension] || 0) + 1;
//     return acc;
//   }, {});

//   return (
//     <div className="container mt-5">
//       <div className='row'>
//         <p className='fw-bold text-center h5'>DOCX Converter Wizard</p>
//         <div className="col-lg-12 mt-3">
//           <div className={`dropzone ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
//             <input {...getInputProps()} accept='.doc , .docx'/>
//             <div className="border border-primary rounded p-5 text-center">
//               <p className="mb-0">{isDragActive ? 'Drop the files here' : 'Drop one or more files here'}</p>
//               {Object.entries(fileCounts).map(([extension, count]) => (
//                 <p key={extension}>{`${count} ${extension.toUpperCase()} file${count > 1 ? 's' : ''} added`}</p>
//               ))}
//             </div>
//           </div>
//         </div>

//         {isLoading && <p className='text-center mt-3'>Processing...</p>}
//         {error && <p>Error: {error}</p>}

//         <div className="col-lg-4 mb-3 mt-2 text-center">
//           <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//           <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//             <option value="docx">DOC</option>
//             <option value="doc">DOCX</option>
//             <option value="pdf">PDF</option>
//             <option value="md">MD</option>
//             <option value="html">HTML</option>
//             <option value="txt">TXT</option>
//             <option value="dot">DOT</option>
//             <option value="docm">DOCM</option>
//             <option value="dotx">DOTX</option>
//             <option value="dotm">DOTM</option>
//             <option value="rtf">RTF</option>
//             <option value="epub">EPUB</option>
//             <option value="ps">PS</option>
//             <option value="pcl">PCL</option>
//             <option value="mhtml">MHTML</option>
//             <option value="odt">ODT</option>
//             <option value="ott">OTT</option>
//             <option value="xps">XPS</option>
//           </select>
//         </div>

//         <div className=''>
//           <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0}>Start Conversion</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;





// import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const App = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [conversionFormat, setConversionFormat] = useState('docx');
//   const [files, setFiles] = useState([]);
//   const [userUsage, setUserUsage] = useState(0);

//   useEffect(() => {
//     const userUsageFromStorage = localStorage.getItem('userUsage');
//     if (userUsageFromStorage) {
//       setUserUsage(parseInt(userUsageFromStorage, 10));
//     }
//   }, []);

//   const onDrop = useCallback(acceptedFiles => {
//     const filteredFiles = acceptedFiles.filter(file => {
//       const acceptedFormats = ['.doc', '.docx'];
//       const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
//       return acceptedFormats.includes(fileExtension.toLowerCase());
//     });
//     setFiles(filteredFiles);
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   const handleStartConversion = async () => {
//     if (userUsage >= 2 || files.length === 0) return;

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       for (let i = 0; i < files.length; i++) {
//         formData.append('files', files[i]);
//       }
//       formData.append('format', conversionFormat);

//       const response = await axios.post('http://localhost:4001/convert', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         responseType: 'blob',
//       });

//       const blob = new Blob([response.data]);
//       saveAs(blob, 'converted_files.zip');

//       // Update user usage
//       setUserUsage(prevUsage => prevUsage + 1);
//       localStorage.setItem('userUsage', userUsage + 1);
//     } catch (err) {
//       setError(err.response?.data.error || 'An error occurred while communicating with the server');
//     } finally {
//       setIsLoading(false);
//       setFiles([]);
//     }
//   };

//   const fileCounts = files.reduce((acc, file) => {
//     const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
//     acc[fileExtension] = (acc[fileExtension] || 0) + 1;
//     return acc;
//   }, {});

//   return (
//     <div className="container mt-5">
//       <div className='row'>
//         <p className='fw-bold text-center h5'>DOCX Converter Wizard</p>
//         <div className="col-lg-12 mt-3">
//           <div className={`dropzone ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
//             <input {...getInputProps()} accept='.doc , .docx'/>
//             <div className="border border-primary rounded p-5 text-center">
//               <p className="mb-0">{isDragActive ? 'Drop the files here' : 'Drop one or more files here'}</p>
//               {Object.entries(fileCounts).map(([extension, count]) => (
//                 <p key={extension}>{`${count} ${extension.toUpperCase()} file${count > 1 ? 's' : ''} added`}</p>
//               ))}
//             </div>
//           </div>
//         </div>

//         {isLoading && <p className='text-center mt-3'>Processing...</p>}
//         {error && <p>Error: {error}</p>}

//         <div className="col-lg-4 mb-3 mt-2 text-center">
//           <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
//           <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
//           <option value="docx">DOC</option>
//             <option value="doc">DOCX</option>
//             <option value="pdf">PDF</option>
//             <option value="md">MD</option>
//             <option value="html">HTML</option>
//             <option value="txt">TXT</option>
//             <option value="dot">DOT</option>
//             <option value="docm">DOCM</option>
//             <option value="dotx">DOTX</option>
//             <option value="dotm">DOTM</option>
//             <option value="rtf">RTF</option>
//             <option value="epub">EPUB</option>
//             <option value="ps">PS</option>
//             <option value="pcl">PCL</option>
//             <option value="mhtml">MHTML</option>
//             <option value="odt">ODT</option>
//             <option value="ott">OTT</option>
//             <option value="xps">XPS</option>
//           </select>
//         </div>

//         <div className=''>
//           <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0 || userUsage >= 2}>
//             Start Conversion
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;



import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'; 

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [conversionFormat, setConversionFormat] = useState('docx');
  const [files, setFiles] = useState([]);
  const [userUsage, setUserUsage] = useState(0);

  useEffect(() => {
    const userUsageFromStorage = localStorage.getItem('userUsage');
    if (userUsageFromStorage) {
      setUserUsage(parseInt(userUsageFromStorage, 10));
    }
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    const filteredFiles = acceptedFiles.filter(file => {
      const acceptedFormats = ['.doc', '.docx'];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
      return acceptedFormats.includes(fileExtension.toLowerCase());
    });
    setFiles(filteredFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleStartConversion = async () => {
    if (userUsage >= 2 || files.length === 0) return;

    setIsLoading(true);

    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('format', conversionFormat);

      const response = await axios.post('https://test.quickcoupondeals.com/convert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data]);
      saveAs(blob, 'converted_files.zip');

      // Update user usage
      const updatedUsage = userUsage + 1;
      setUserUsage(updatedUsage);
      localStorage.setItem('userUsage', updatedUsage);

      // Show success message
      Swal.fire('Conversion Successful', 'Your files have been converted.', 'success');

      if (updatedUsage >= 2) {
        // Show alert for daily limit reached
        Swal.fire('Daily Limit Reached', 'You have reached your daily upload limit.', 'warning');
      }
    } catch (err) {
      // setError(err.response?.data.error || 'An error occurred while communicating with the server'); 
      // Show error message
      Swal.fire(err.response?.data.error || 'An error occurred while communicating with the server');
    } finally {
      setIsLoading(false);
      setFiles([]);
    }

    
  };

  const fileCounts = files.reduce((acc, file) => {
    const fileExtension = file.name.slice(file.name.lastIndexOf('.'));
    acc[fileExtension] = (acc[fileExtension] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className="container mt-5">
      <div className='row'>
        <p className='fw-bold text-center h5'>DOCX Converter Wizard</p>
        <div className="col-lg-12 mt-3">
          <div className={`dropzone ${isDragActive ? 'drag-active' : ''}`} {...getRootProps()}>
            <input {...getInputProps()} accept='.doc , .docx'/>
            <div className="border border-primary rounded p-5 text-center">
              <p className="mb-0">{isDragActive ? 'Drop the files here' : 'Drop one or more files here'}</p>
              {Object.entries(fileCounts).map(([extension, count]) => (
                <p key={extension}>{`${count} ${extension.toUpperCase()} file${count > 1 ? 's' : ''} added`}</p>
              ))}
            </div>
          </div>
        </div>

        {isLoading && <p className='text-center mt-3'>Processing...</p>}
        {error && <p>Error: {error}</p>}

        <div className="col-lg-4 mb-3 mt-2 text-center">
          <label htmlFor="conversionFormat" className="form-label">Select Conversion Format:</label>
          <select id="conversionFormat" className="form-select" value={conversionFormat} onChange={(e) => setConversionFormat(e.target.value)}>
            <option value="docx">DOC</option>
            <option value="doc">DOCX</option>
            <option value="pdf">PDF</option>
            <option value="md">MD</option>
            <option value="html">HTML</option>
            <option value="txt">TXT</option>
            <option value="dot">DOT</option>
            <option value="docm">DOCM</option>
            <option value="dotx">DOTX</option>
            <option value="dotm">DOTM</option>
            <option value="rtf">RTF</option>
            <option value="epub">EPUB</option>
            <option value="ps">PS</option>
            <option value="pcl">PCL</option>
            <option value="mhtml">MHTML</option>
            <option value="odt">ODT</option>
            <option value="ott">OTT</option>
            <option value="xps">XPS</option>
          </select>
        </div>

        <div className=''>
          <button className="btn btn-primary" onClick={handleStartConversion} disabled={isLoading || files.length === 0 || userUsage >= 2}>
            Start Conversion
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
